import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["onUpdate:modelValue"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["onUpdate:modelValue"]
const _hoisted_4 = ["onUpdate:modelValue"]
const _hoisted_5 = ["onUpdate:modelValue"]
const _hoisted_6 = ["onUpdate:modelValue"]
const _hoisted_7 = ["onUpdate:modelValue", "min", "max"]
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "block-details",
    style: _normalizeStyle({ width: `${_ctx.blockWidth}px` })
  }, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.description), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parsedFields, (field, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "field"
      }, [
        (field.type === 'Dropdown')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("label", null, _toDisplayString(field.name) + ":", 1),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": ($event: any) => ((field.value) = $event)
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.options, (option) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: option,
                    value: option
                  }, _toDisplayString(option), 9, _hoisted_2))
                }), 128))
              ], 8, _hoisted_1), [
                [_vModelSelect, field.value]
              ])
            ], 64))
          : (field.type === 'Date')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("label", null, _toDisplayString(field.name) + ":", 1),
                _withDirectives(_createElementVNode("input", {
                  type: "date",
                  "onUpdate:modelValue": ($event: any) => ((field.value) = $event)
                }, null, 8, _hoisted_3), [
                  [_vModelText, field.value]
                ])
              ], 64))
            : (field.type === 'Time')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createElementVNode("label", null, _toDisplayString(field.name) + ":", 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "time",
                    "onUpdate:modelValue": ($event: any) => ((field.value) = $event)
                  }, null, 8, _hoisted_4), [
                    [_vModelText, field.value]
                  ])
                ], 64))
              : (field.type === 'Input')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _createElementVNode("label", null, _toDisplayString(field.name) + ":", 1),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": ($event: any) => ((field.value) = $event)
                    }, null, 8, _hoisted_5), [
                      [_vModelText, field.value]
                    ])
                  ], 64))
                : (field.type === 'Textarea')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                      _createElementVNode("label", null, _toDisplayString(field.name) + ":", 1),
                      _withDirectives(_createElementVNode("textarea", {
                        "onUpdate:modelValue": ($event: any) => ((field.value) = $event)
                      }, null, 8, _hoisted_6), [
                        [_vModelText, field.value]
                      ])
                    ], 64))
                  : (field.type === 'int')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                        _createElementVNode("label", null, _toDisplayString(field.name) + ":", 1),
                        _withDirectives(_createElementVNode("input", {
                          type: "number",
                          "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                          min: field.min,
                          max: field.max
                        }, null, 8, _hoisted_7), [
                          [_vModelText, field.value]
                        ])
                      ], 64))
                    : (field.type === 'email')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                          _createElementVNode("label", null, _toDisplayString(field.name) + ":", 1),
                          _withDirectives(_createElementVNode("input", {
                            type: "email",
                            "onUpdate:modelValue": ($event: any) => ((field.value) = $event)
                          }, null, 8, _hoisted_8), [
                            [_vModelText, field.value]
                          ])
                        ], 64))
                      : (field.type === 'tel')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                            _createElementVNode("label", null, _toDisplayString(field.name) + ":", 1),
                            _withDirectives(_createElementVNode("input", {
                              type: "tel",
                              "onUpdate:modelValue": ($event: any) => ((field.value) = $event)
                            }, null, 8, _hoisted_9), [
                              [_vModelText, field.value]
                            ])
                          ], 64))
                        : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 4))
}