import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "choose-service-page" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "search-section" }
const _hoisted_4 = { class: "tiles-section" }
const _hoisted_5 = { class: "tiles-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CancelButton = _resolveComponent("CancelButton")!
  const _component_TileComponent = _resolveComponent("TileComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_CancelButton, {
        buttonText: "Back",
        onClick: _ctx.goBack,
        class: "cancel-button"
      }, null, 8, ["onClick"]),
      _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "page-title" }, "Choose a Action", -1))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: "search-bar",
        placeholder: "Search Action",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event))
      }, null, 512), [
        [_vModelText, _ctx.searchQuery]
      ]),
      _createElementVNode("button", {
        class: "clear-search",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearSearchQuery && _ctx.clearSearchQuery(...args)))
      }, "✖")
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "divider" }, null, -1)),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTiles, (tile, index) => {
          return (_openBlock(), _createBlock(_component_TileComponent, {
            key: index,
            title: tile.title,
            description: tile.description,
            backgroundColor: tile.backgroundColor,
            name: tile.name,
            onTileSelected: _ctx.handleTileSelection
          }, null, 8, ["title", "description", "backgroundColor", "name", "onTileSelected"]))
        }), 128))
      ])
    ])
  ]))
}