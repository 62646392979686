import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createVNode(_component_router_link, {
      to: _ctx.linkPath,
      class: "link"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.linkText), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}