import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tile-title" }
const _hoisted_2 = { class: "tile-description" }
const _hoisted_3 = { class: "tile-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tile",
    style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor }),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectTile && _ctx.selectTile(...args)))
  }, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.description), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.name), 1)
  ], 4))
}