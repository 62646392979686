import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "auth-button",
    style: _normalizeStyle({ backgroundColor: _ctx.buttonColor, color: _ctx.textColor }),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.icon,
          alt: "icon",
          class: "auth-icon"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
  ], 4))
}