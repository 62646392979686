import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main-page" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "applets-section" }
const _hoisted_4 = { class: "applets-container" }
const _hoisted_5 = { class: "applets-grid" }
const _hoisted_6 = { class: "applets-container" }
const _hoisted_7 = { class: "applets-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeaderMain = _resolveComponent("AppHeaderMain")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AppletTile = _resolveComponent("AppletTile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppHeaderMain),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "background-image" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Automation for business and home", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, "Save time and get more done", -1)),
      _createVNode(_component_router_link, {
        to: "/sign-up",
        class: "start-btn"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Start Today")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("section", _hoisted_3, [
      _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "applets-title" }, "Get started with any Applet", -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredServices.slice(0, 4), (applet, index) => {
            return (_openBlock(), _createBlock(_component_AppletTile, {
              key: index,
              title: applet.title,
              backgroundColor: applet.color
            }, null, 8, ["title", "backgroundColor"]))
          }), 128))
        ])
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "applets-title" }, "... or choose from 4+ services", -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredServices.slice(0, 4), (applet, index) => {
            return (_openBlock(), _createBlock(_component_AppletTile, {
              key: index,
              title: applet.title,
              backgroundColor: applet.color
            }, null, 8, ["title", "backgroundColor"]))
          }), 128))
        ])
      ])
    ])
  ]))
}