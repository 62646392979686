import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "applets-container" }
const _hoisted_2 = {
  class: "applets-grid",
  ref: "appletsGrid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_CancelButton = _resolveComponent("CancelButton")!
  const _component_CompTitle = _resolveComponent("CompTitle")!
  const _component_CompDescription = _resolveComponent("CompDescription")!
  const _component_AppletTile = _resolveComponent("AppletTile")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("header", null, [
      _createVNode(_component_AppHeader),
      _createVNode(_component_CancelButton, {
        buttonText: "Back",
        onClick: _ctx.goBack
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", {
      ref: "serviceHeader",
      class: "service-header",
      style: _normalizeStyle({ backgroundColor: _ctx.service.color })
    }, [
      _createVNode(_component_CompTitle, {
        title: _ctx.service.title
      }, null, 8, ["title"]),
      _createVNode(_component_CompDescription, {
        description: _ctx.service.description
      }, null, 8, ["description"])
    ], 4),
    _createElementVNode("section", {
      class: "applets-section",
      style: _normalizeStyle({ marginTop: `${_ctx.appletsSectionTop}px` })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredApplets, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (item.type === 'applet' && item.tags.includes(_ctx.service.title))
                ? (_openBlock(), _createBlock(_component_AppletTile, {
                    key: 0,
                    title: item.title,
                    "background-color": item.color
                  }, null, 8, ["title", "background-color"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ], 512)
      ])
    ], 4)
  ]))
}