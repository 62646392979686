import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "connect-button" }
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "circle",
      style: _normalizeStyle({ backgroundColor: _ctx.buttonColor })
    }, null, 4),
    _createElementVNode("span", {
      class: "connect-text",
      textContent: _toDisplayString(_ctx.text),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onConnectOrDisconnectButton && _ctx.onConnectOrDisconnectButton(...args)))
    }, null, 8, _hoisted_2)
  ]))
}