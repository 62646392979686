import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "password-field" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "input-container" }
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", { for: _ctx.id }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        id: _ctx.id,
        type: "password",
        placeholder: "Can't retrieve password due to security",
        disabled: ""
      }, null, 8, _hoisted_4),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changePassword && _ctx.changePassword(...args))),
        class: "change-password-btn"
      }, "Change password")
    ])
  ]))
}