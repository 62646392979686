import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"
import _imports_0 from '@/assets/logo.png'
import _imports_1 from '@/assets/profile-icon.svg'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "nav" }
const _hoisted_3 = {
  key: 0,
  class: "profile-menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/",
      class: "logo-link"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Logo",
          class: "logo"
        }, null, -1)
      ])),
      _: 1
    }),
    _createElementVNode("nav", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/explore/all",
        class: "nav-item"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Explore")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/create",
        class: "nav-item create-btn"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Create")
        ])),
        _: 1
      }),
      _createElementVNode("div", {
        class: "profile-container",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleProfileMenu && _ctx.toggleProfileMenu(...args)))
      }, [
        _cache[9] || (_cache[9] = _createElementVNode("img", {
          src: _imports_1,
          alt: "Profile",
          class: "profile-icon"
        }, null, -1)),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.isProfileMenuOpen)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_router_link, {
                    to: "/account",
                    class: "menu-item"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Account")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_router_link, {
                    to: "/services",
                    class: "menu-item"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("My services")
                    ])),
                    _: 1
                  }),
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "separator" }, null, -1)),
                  _createVNode(_component_router_link, {
                    to: "/help",
                    class: "menu-item"
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Help")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_router_link, {
                    to: "/",
                    class: "menu-item logout",
                    onClick: _ctx.logUserOut
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Log out")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}