import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "create-applet-page" }
const _hoisted_2 = { class: "applet-creation" }
const _hoisted_3 = { class: "ifThenContainer" }
const _hoisted_4 = {
  key: 1,
  class: "selected-service"
}
const _hoisted_5 = {
  key: 1,
  class: "selected-service"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_CancelButton = _resolveComponent("CancelButton")!
  const _component_AddButton = _resolveComponent("AddButton")!
  const _component_BlockDetails = _resolveComponent("BlockDetails")!
  const _component_AddServiceModal = _resolveComponent("AddServiceModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createVNode(_component_AppHeader),
      _createVNode(_component_CancelButton, { onClick: _ctx.goBack }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("h1", null, "Create", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "ifThenBlock",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglePhase('if')))
        }, [
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "block-text" }, "If", -1)),
          (!_ctx.ifCondition && !_ctx.isSecondPhase.if)
            ? (_openBlock(), _createBlock(_component_AddButton, {
                key: 0,
                onClick: _ctx.showAddServiceModalForIf,
                route: '/create/add-action',
                class: "add-button-right"
              }, null, 8, ["onClick"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.ifCondition?.name || 'No service selected'), 1))
        ]),
        (_ctx.isSecondPhase.if)
          ? (_openBlock(), _createBlock(_component_BlockDetails, {
              key: 0,
              title: _ctx.ifCondition?.title || 'Default Title',
              description: _ctx.ifCondition?.description || 'Default Description',
              fieldsData: ['Dropdown:ServiceType', 'Date:StartDate', 'Time:StartTime', 'Input:Username', 'int:Age'],
              blockWidth: 800
            }, null, 8, ["title", "description"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "ifThenBlock",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.togglePhase('then')))
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "block-text" }, "Then", -1)),
          (!_ctx.thenAction && !_ctx.isSecondPhase.then)
            ? (_openBlock(), _createBlock(_component_AddButton, {
                key: 0,
                onClick: _ctx.showAddServiceModalForThen,
                route: '/create/add-reaction',
                class: "add-button-right"
              }, null, 8, ["onClick"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.thenAction?.name || 'No action selected'), 1))
        ]),
        (_ctx.isSecondPhase.then)
          ? (_openBlock(), _createBlock(_component_BlockDetails, {
              key: 1,
              title: _ctx.ifCondition?.title || 'Default Title',
              description: _ctx.ifCondition?.description || 'Default Description',
              fieldsData: ['Dropdown:ServiceType', 'Date:StartDate', 'Time:StartTime', 'Input:Username', 'int:Age'],
              blockWidth: 800
            }, null, 8, ["title", "description"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isModalOpen)
      ? (_openBlock(), _createBlock(_component_AddServiceModal, {
          key: 0,
          onSelectService: _ctx.handleServiceSelection,
          onClose: _ctx.closeModal
        }, null, 8, ["onSelectService", "onClose"]))
      : _createCommentVNode("", true)
  ]))
}