import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthButton = _resolveComponent("AuthButton")!
  const _component_AuthLayout = _resolveComponent("AuthLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("img", {
      src: _imports_0,
      class: "ifttt-logo"
    }, null, -1)),
    _createVNode(_component_AuthLayout, {
      title: "Forgot Password",
      linkText: "Remember your password? Sign in here",
      linkPath: "/sign-in"
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForgotPassword && _ctx.submitForgotPassword(...args)), ["prevent"])),
          class: "forgot-password-form"
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            type: "email",
            placeholder: "Email",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.email]
          ]),
          _createVNode(_component_AuthButton, { text: "Reset Password" })
        ], 32)
      ]),
      _: 1
    })
  ], 64))
}