import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo-white.png'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/",
      class: "logo-link"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Logo",
          class: "logo"
        }, null, -1)
      ])),
      _: 1
    }),
    _createElementVNode("nav", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/sign-in",
        class: "nav-item"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Log In")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/sign-up",
        class: "get-started-btn"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Get Started")
        ])),
        _: 1
      })
    ])
  ]))
}