import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_CancelButton = _resolveComponent("CancelButton")!
  const _component_CompTitle = _resolveComponent("CompTitle")!
  const _component_CompConnectButton = _resolveComponent("CompConnectButton")!
  const _component_CompDescription = _resolveComponent("CompDescription")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("header", null, [
      _createVNode(_component_AppHeader),
      _createVNode(_component_CancelButton, {
        buttonText: "Back",
        onClick: _ctx.goBack
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", {
      class: "applet-header",
      style: _normalizeStyle({ backgroundColor: _ctx.applet?.color })
    }, [
      _createVNode(_component_CompTitle, {
        title: _ctx.applet?.title || ''
      }, null, 8, ["title"])
    ], 4),
    _createElementVNode("div", {
      class: "applet-body",
      style: _normalizeStyle({ backgroundColor: _ctx.applet?.color })
    }, [
      _createVNode(_component_CompConnectButton, {
        "applet-id": _ctx.applet?.id || 0,
        buttonColor: "applet?.color || 'blue'"
      }, null, 8, ["applet-id"]),
      _createVNode(_component_CompDescription, {
        description: _ctx.applet?.description || ''
      }, null, 8, ["description"])
    ], 4)
  ]))
}