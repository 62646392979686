import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "applet-title" }
const _hoisted_3 = { class: "applet-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `/applet/${_ctx.title.replace(/\s+/g, '-')}`,
    class: "applet-tile-link"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "applet-tile",
        style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor })
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.icon,
              class: "applet-icon",
              alt: "icon"
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1)
      ], 4)
    ]),
    _: 1
  }, 8, ["to"]))
}