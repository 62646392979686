import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/profile-icon.svg'


const _hoisted_1 = { class: "account-settings" }
const _hoisted_2 = { class: "form-section" }
const _hoisted_3 = { class: "form-section" }
const _hoisted_4 = { class: "form-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_CancelButton = _resolveComponent("CancelButton")!
  const _component_PasswordField = _resolveComponent("PasswordField")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeader),
    _createVNode(_component_CancelButton, {
      buttonText: "Back",
      onClick: _ctx.goBack
    }, null, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Account Settings", -1)),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "profile-section" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Profile",
          class: "profile-icon"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("label", { for: "username" }, "Username", -1)),
        _withDirectives(_createElementVNode("input", {
          id: "username",
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
          class: "text-input"
        }, null, 512), [
          [_vModelText, _ctx.username]
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { for: "email" }, "Email", -1)),
        _withDirectives(_createElementVNode("input", {
          id: "email",
          type: "email",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
          class: "text-input"
        }, null, 512), [
          [_vModelText, _ctx.email]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_PasswordField, {
          label: "Password",
          id: "password"
        })
      ]),
      _createElementVNode("button", {
        class: "save-button",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.saveChange && _ctx.saveChange(...args)))
      }, "Save Change")
    ])
  ], 64))
}